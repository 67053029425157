<template>
  <Button
    type="button"
    class="btn btn-danger"
    :disabled="campaign.status == 'FINISHED'"
    :loading="loader"
    @click="submit"
  >
    Encerrar Campanha
  </Button>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'

import Button from '@/components/common/Button/Button'

export default {
  name: 'EndCampaignButton',
  props: ['campaign'],
  components: {
    Button
  },
  data () {
    return {
      loader: false
    }
  },
  created () {
  },
  methods: {
    async submit () {
      this.loader = true
      try {
        await CampaignsService.endCampaign(this.campaign.id)
        this.$emit('ended', this.campaign)
      } catch (error) {
        this.$emit('error', error)
      }
      this.loader = false
    }
  }
}
</script>
